.section-header {

	text-align: center;
	position: relative;
	margin-bottom: $spacing-unit * 1.5;

	&:before {
		@include vertical-align;

		content: '';
		display: block;
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		border-bottom: 1px solid $color-gray-white-dust;
		transform: translateZ(0);
	}
}


.section-header-title {
	@extend %header-with-rule;

	display: inline-block;
	max-width: 70%;
	position: relative;
	background-color: $color-brand-white;
	padding: 0 1rem;

	&--smaller {
		font-size: px-to-rem(26px);
	}

	.fab, .fa  {
		display: inline-block;
		margin-left: 0.5rem;
	}

	.fa-facebook-square {
		color: #3b5998;
	}

	.fa-linkedin-square {
		color: #007bb6;
	}

	.fa-twitter-square {
		&:before {
			font-family: icomoon !important;
			content: "\e913";
			font-size: 0.8em;
		}
	}

	.fa-weixin {
		color: #09b83e;
	}
	.fa-podcast {
		color: #b150e2
	}

	@include bp-max($bp-mobile-portrait){
		max-width: calc(100% - 30px);
	}

	&__icon-image {
		height: 2rem;
		margin-left: 0.5rem;
		margin-bottom: -0.3rem;
	}
}
