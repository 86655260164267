// Spinning Icons
// --------------------------

.#{$bt-css-prefix}-spin {
  -webkit-animation: bt-spin 2s infinite linear;
          animation: bt-spin 2s infinite linear;
}

.#{$bt-css-prefix}-pulse {
  -webkit-animation: bt-spin 1s infinite steps(8);
          animation: bt-spin 1s infinite steps(8);
}

@-webkit-keyframes bt-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes bt-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
