@keyframes nojs-fadein {
  0% {opacity: 0}
  100% {opacity: 1}
}

.homepage {
	height: 100vh;
  padding-left: 0;
  .site-header {
    background: none;
    display: none;
  }
  .site-nav-main-wrap {
    background: none;
    .site-nav-main {
      height: 100vh;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
    }
  }

  &__hero {

    &--nojs-fadein {
      animation: none;
    }
  }

  &__padding {
    padding-top: 3rem;
  }

  .hero--without-feature-card {
    & ~ .homepage__padding {
      padding-top: 0;
    }
  }

  &__content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  &__perpetual {
    position: fixed;
    z-index: 0;
    left: 50%;
    top: 10%;
    width: 100%;
    max-width: 680px;
    opacity: 1;
    &--hide {
      opacity: 0;
    }
  }
  .container {
    max-width: $width-max-home;
    margin: 0 auto;
    padding: 0 4rem;
  }
	.site-main {
		min-height: 100%;
  }

	.home-panel-item {
		position: relative;
		height: 100%;

		&:last-of-type {
      height: $homepage-last-panel-height;
			.site-main-content {
				display: flex;
				flex-direction: column;

				&::before {
					content: "";
					height: 100vh;
					background-color: $color-brand-white;
					width: 100%;
					bottom: 100%;
				    position: absolute;
				}
			}

			.footer-links {
				height: auto;
			}
		}
	}
	.home-header {
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    margin: 4rem 0;
    &::before,
    &::after {
      background-color: #BABABA;
      content: '';
      height: 1px;
      width: 100%;
    }
    &__content {
      padding: 0 1.5rem;
      font-size: 24px;
      line-height: 1.3em;
      color: $color-gray-med;
      font-style: italic;
      font-family: $font-family-titles;

      &:lang(ja){
        font-family: $font-family-titles-ja;
      }
    }
    &__text {
      font-size: 1em;
      &--primary {
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
        font-style: normal;
        color: $color-brand-black;
        font-size: 0.8em;
        letter-spacing: 0.6em;
      }
    }
  }

  @include bp-max($bp-desktop-large) {
    &__hero {
      padding-left: calc(#{$site-header-nav-width-desktop} + 2rem);
    }
    &__padding,
    .sidley-footer {
      padding-left: $site-header-nav-width-desktop;
    }
  }

  @include bp-max($bp-desktop) {
    &__padding {
      padding-top: 0;
    }
  }

  @include bp-max($bp-tablet) {
    .site-header {
      display: block;
    }
    &__hero {
      padding-left: calc(#{$site-header-nav-width-tablet} + 2rem);
    }
    &__padding,
    .sidley-footer {
      padding-left: $site-header-nav-width-tablet;
    }
  }

  @include bp-max($bp-mobile-landscape){
    .home-panel-item {
      &:last-of-type {
				height: $homepage-last-panel-height-mobile;
      }
    }
  }

	@include bp-max($bp-mobile) {
    .container {
      padding: 0 2rem;
    }
		.articles-grid {
			box-shadow: 0 3px 3px 1px rgba($color-brand-dark-black, 0.25);
    }
    .site-header {
      background: $color-brand-gray;
    }
    .site-nav-main-wrap {
      .site-nav-main {
        height: auto;
      }
    }
    &__hero {
      padding-left: 2rem;
    }
    &__padding,
    .sidley-footer {
      padding-left: 0;
    }
	}
}

$zindex-perpetual-graphic: 5;
$zindex-site-nav-bg: 3;
$zindex-hero-background: 4;
$zindex-main-content: 6;


// Midnight Styles for Menu and header
.homepage {
  &__perpetual {
    z-index: $zindex-perpetual-graphic;
  }
  .hero__background {
    z-index: $zindex-hero-background;
    
    &--override {
      z-index: 0;
      
      @include bp-max($bp-desktop) {
        z-index: -1;
      }
    }
  }
  &__padding,
  .hero {
    z-index: $zindex-main-content;
  }

  &__padding {
    position: relative;
  }

  .site-nav-main-wrap {
    .midnightInner {
      overflow: hidden !important;
    }
    .midnightHeader {
      &.transparent {
        background: none;
        .site-nav-link {
          color: #fff;
        }
      }
    }
  }
  &__logo {
    z-index: $zindex-header - 1;
    position: fixed;
    left: 0;
    top: 0;
    
    width: $site-header-nav-width;
    z-index: $zindex-header;
    text-align: center;

    @include bp-max($bp-desktop-large) {
      width: $site-header-nav-width-desktop;

    }
    @include bp-max($bp-tablet) {
      width: $site-header-nav-width-tablet;
    }

    @include bp-max($bp-mobile) {
      display: none;
    }
  }

  &__logo-holder {
    position: relative;
    width: 70%;
    padding-top: 50px + $site-header-bar-height;
    max-width: 170px;
    margin: 0 auto;
    
  }

  &__logo-midnight {
    width: 100%;
    display: block;
    width: 100%;
    padding-bottom: 22.7%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-image: url('/assets/release/images/logostacked.svg');

    .midnightHeader.default & {
      background-position: 0 0;
    }

    .midnightHeader.transparent &{
      background-position: 0 99%;
    }
  }

  .site-main {
    // Gray Background Navigation
    &:before {
      content: '';
      background-color: $bg-site-nav;
      display: block;
      width: $site-header-nav-width;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      z-index: $zindex-site-nav-bg;

      @include bp-max($bp-desktop-large) {
        min-width: $site-header-nav-width-tablet;
        width: $site-header-nav-width-desktop;
      }
      @include bp-max($bp-tablet) {
        width: $site-header-nav-width-tablet;
      }

      @include bp-max($bp-mobile) {
        display: none;
      }
    }
  }
}


.hero__scroll,  .cookie-popup, .sidley-footer, .carousel__order, .homepage__logo {
  display: none;
}

.homepage__hero {
  max-width: $width-max-home;
  margin: 0 auto;
  padding: 0 4rem;
}

.carousel {
  max-width: $width-max-home;
  margin: 0 auto;
  padding: 0 4rem;
  &__card {
    margin: 1rem 0;
    page-break-inside: avoid;
  }
}
.staying-informed__tile {
  page-break-inside: avoid;
}

.staying-informed, .story {
  opacity: 1 !important;
  visibility: visible !important;
  transform: none !important;
}
.story {
  margin: 5rem auto;
  padding: 0 2rem;
  max-width: 900px;
  text-align: center;
  position: relative;
  &__header {
    margin-bottom: 2rem;
  }
}
