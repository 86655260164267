.article-content--sub-page,
    div.rte-expand + .section-header,
    div.rte-expand + .rte-expand {
        margin-top:  $spacing-unit-section-large;
    }

.our-work-single{
    padding-top: $spacing-unit-section-large;
}

.rich-text-content {
    a {
        font-weight: bold;
    }
}

.rich-text {
    $list-side-spacing: 1.5rem;
    $vertical-spacing: 1rem;

    margin-bottom: $spacing-unit;
    font-family: $font-family-primary;
    line-height: 1.5;
    &:lang(ja){
        font-family: $font-family-primary-ja;
    }

    &--rommy-bottom {
        margin-bottom: $spacing-unit-section;
    }

    &--featured {
        width: 100%;
        border-top: 2px solid $color-green-blue-lagoon;
        background-color: $bg-articles;
        color: $color-brand-black;
        position: relative;
        overflow: hidden;
        font-size: 1.335rem;
        line-height: 1.335;
        padding: 4% 4% 12%;

        &:before {
            content: "";
            height: 400px;
            width: 400px;
            background: url(../images/complex-vector-gray.svg) no-repeat;
            background-size: 400px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 90%;
            z-index: 0;
        }

        @media (min-width: $bp-mobile) {
            padding: 3% 6% 10% 3%;
            border-width: 4px;
    
            &:before {
                height: 500px;
                width: 500px;
                background-size: 500px;
                left: 70%;
                top: 50%;
                transform: translateX(0);
            }
        }
    }

    &.update-disclaimer{
        font-size: 18px;
        font-style:italic;
        margin-top:1em;
        padding-top:1em;
    }

    > :first-child {
        margin-top: 0;
    }

    > .module {
        &:first-child {
            padding-top: 0;
        }
    }

    p {
        margin: 0;
        margin-top: $vertical-spacing;
    }

    &:not(.rte-expand-full) {
        p {
            &:first-of-type {
                margin-top: 0;
            }
        }
         ul + p {
            &:first-of-type {
        margin-top: $vertical-spacing;
    }
    }
    }



    ul,
    ol {
        margin: $vertical-spacing 0 0;
        -webkit-padding-start: 0; //reset

        ul,
        ol {
            margin-left: 1.25em;
        }
    }

    li {
        line-height: 1.5;
        margin: ($vertical-spacing * .75) 0 0;
        position: relative;

        &.no-bullets & {
            margin-left: 0;
            &:before {
                content: '';
            }
        }
    }
    ol > li {
        margin-left: 1.5em;
    }
    ul  li {
        list-style-type: none;
        padding-left:20px;
        p{
            display:inline;
        }

        &:before {
            content: "•";
            display: inline-block; // Prevent larger than normal line size
            height: 0; // Prevent larger than normal line size
            line-height: 1.5;
            position:absolute;
            top:0;
            left:5px;
        }

        ul  li {
            &:before {
                content: '\25E6'; //circle list style for nested list
            }
        }
    }

    h1, h2, h3, h4, h5, h6 {
            &:not(.section-header-title){
            font-family: $font-family-sans-serif-bold;
            color: $color-brand-dark-black;
        }
    }

    h4 {
        font-size: px-to-rem(18px);
    }

    h2, h3 {
         &:not(.section-header-title){
            margin-top: $spacing-unit;
        }
    }

    blockquote,
    .blockquote {
        font-family: $font-family-titles;
        color: $color-brand-red;
        font-weight: 400;
        font-size: rem(32px);
        margin-left: 0;
        margin-right: 0;
        line-height: 1.25;
        position: relative;
        padding-left: 1.5em;
        &:lang(ja){
            font-family: $font-family-titles-ja;
        }
    }

    .blockquote-attribution {
        font-size: .8rem;
        line-height: 1.2;
        color: $color-gray-med;
        font-family: $font-family-primary;
        font-style:italic;
        &:lang(ja){
            font-family: $font-family-primary-ja;
        }
    }

    .no-bullets {
        margin-left: 0;
        list-style: none;

        li {
            padding-bottom: $spacing-unit-small;
            margin-top: 0;

            &:last-of-type {
                padding-bottom: 0;
            }
        }
    }


    .two-column {
        margin-left: 0;
        list-style: none;
        columns: 2 auto;

        > li {
            -webkit-column-break-inside: avoid;
            page-break-inside: avoid;
            break-inside: avoid;
            margin-bottom: $spacing-unit-small;
            margin-top: 0;
            display: inline-block;
            width: 100%;
        }

        @include bp-max($bp-tablet) {
            columns: 1;
            margin-bottom: $spacing-unit-small * -1;
        }
    }

    .rte-image-large {
        margin-top: $spacing-unit;
    }
    // More practical CSS...
    // using mobile first method (IE8,7 requires respond.js polyfill https://github.com/scottjehl/Respond)

    $breakpoint-alpha: $bp-tablet; // adjust to your needs
    table.rwd-table {
        margin: 1em 0;
        min-width: 300px; // adjust to your needs
        tr {
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
        }

        th {
            display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!
        }

        td {
            display: block;

            &:first-child {
                padding-top: .5em;
            }

            &:last-child {
                padding-bottom: .5em;
            }

            &:before {
                content: attr(data-th)": "; // who knew you could do this? The internet, that's who.
                font-weight: bold;
                // optional stuff to make it look nicer
                width: 6.5em; // magic number :( adjust according to your own content
                display: inline-block;
                // end options
                @media (min-width: $breakpoint-alpha) {
                    display: none;
                }
            }
        }

        th, td {
            text-align: left;

            @media (min-width: $breakpoint-alpha) {
                display: table-cell;
                padding: .25em .5em;

                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }

    div.threeCol1 {
        float: left;
        clear: left;
        width: 100%;
        position: relative;
    }

    div.threeCol1 div.threeCol2 {
        clear: left;
        float: left;
        width: 100%;
        position: relative;
        right: 33.333%;
        border-right: 1px solid black;
    }

    div.threeCol1 div.threeCol2 div.threeColumn {
        float: left;
        width: 100%;
        position: relative;
        right: 33.33%;
        border-right: 1px solid black;
    }

    div.threeCol1 div.threeCol2 div.threeColumn img {
        height: 100%;
    }

    div.threeCol1 div.threeCol2 div.threeColumn .col1 {
        float: left;
        width: 29.33%;
        position: relative;
        left: 67%;
        overflow: hidden;
    }

    div.threeCol1 div.threeCol2 div.threeColumn .col2 {
        float: left;
        width: 29.33%;
        position: relative;
        left: 75%;
        overflow: hidden;
    }

    div.threeCol1 div.threeCol2 div.threeColumn .col3 {
        float: left;
        width: 29.33%;
        position: relative;
        left: 79.5%;
        overflow: hidden;
    }

    div.twoColumn {
        float: left;
        width: 100%;
        clear: both;
    }

    div.twoColumn img {
        height: 100%;
        width: 100%;
    }

    div.twoColumn .col1 {
        float: left;
        width: 40%;
        padding-right: 4%;
    }

    .rte-expand-button {
        margin-bottom: px-to-rem(50px);
    }

    @media all and (max-height: 768px) and (max-width: 480px) {
        div.twoColumn .col1 {
            width: 100%;
            padding: 0 0 10px 0;
            border-right: none;
        }
    }

    @media all and (max-width: 768px) and (max-height: 480px) {
        div.twoColumn .col1 {
            width: 100%;
            padding: 0 0 10px 0;
            border-right: none;
        }
    }

    div.twoColumn .col2 {
        float: left;
        width: 40%;
        padding-left: 4%;
        border-left: none;
    }

    @media all and (max-height: 768px) and (max-width: 480px) {
        div.twoColumn .col2 {
            width: 100%;
            padding: 10px 0 0 0;
            border-right: none;
        }
    }

    @media all and (max-width: 768px) and (max-height: 480px) {
        div.twoColumn .col2 {
            width: 100%;
            padding: 10px 0 0 0;
            border-right: none;
        }
    }
}
