html {
    height: 100%;
}

body {
    padding-left: $site-header-nav-width;

    @include bp-max($bp-desktop-large) {
        padding-left: $site-header-nav-width-desktop;
    }

    @include bp-max($bp-tablet) {
        padding-left: $site-header-nav-width-tablet;
    }

    @include bp-max($bp-mobile) {
        padding-left: 0; 

        &.is-active-mobile-menu, &.is-active-search {
            position: fixed;
            overflow-y: scroll;
            left: 0;
            right: 0;
            .headroom--unpinned {
                transform: translate(0);
           }

           .site-main {
            height: 100%;
            max-height: 100%;
            overflow: hidden;
           }
        }
    }
}

//Offset the height of the site-nav-bar
.site-header-offset {
    margin-top: 2.5rem;

    @include bp-max($bp-mobile) {
        margin-top: 6.1rem;
    }
}

.site-main-content {
    position: relative;
    z-index: 5;
    background-color: $color-brand-white;
}

.site-padding,
.site-main-content {
    padding: 1rem;

    @include bp-min($bp-mobile-landscape) {
        padding: 2rem;
    }

    @include bp-min($bp-desktop) {
        padding: 3rem;
    }
}

.site-fixed-hero {
    position: fixed;
    z-index: 1;
    left: $site-header-nav-width;
    right: 0;
    bottom: 0;
    height: 100%;

    @include bp-max($bp-desktop-large) {
        left: $site-header-nav-width-desktop;
    }

    @include bp-max($bp-tablet) {
        left: $site-header-nav-width-tablet;
    }
}

.site-section {
    margin-top: $spacing-unit-section-large;
}

.page-wrapper {
    margin: 0 auto $spacing-unit-section;

    &.spacing-top {
        margin-top: $spacing-unit-section;
    }

    &.spacing-top--large {
        margin-top: $spacing-unit-section-large;
    }

    @include bp-max($bp-mobile) {
    }

    &.our-story-landing-wrapper {
        margin-top: 0;
        margin-bottom: rem(70px);

        @include bp-max($bp-desktop) {
            margin-bottom: 29px;

            + .page-details {
                margin-top: 29px;
            }
        }

        @include bp-max($bp-mobile) {
            margin-bottom: 22px;

            + .page-details {
                margin-top: 22px;
            }
        }
    }

    &.page-wrapper--people-detail-asian {
        .rich-text,
        .section-header-title,
        blockquote,
        .articles-grid-list,
        .insight-item-link,
        .header-label {
            font-family: "georgia" !important;
            &:lang(ja){
                font-family: "kepler-std", "Times New Roman", "MS Mincho" !important;
            }
        }
    }
}

.inner-wrapper {
    max-width: px-to-rem($width-max-page);
    margin: 0 auto;

    &--section {
        & + & {
            margin-top: $spacing-unit-section-large;
        }

        & ~ & {
            margin-top: $spacing-unit-section-large;
        }
    }

    .contacts-module + &--section {
        margin-top: $spacing-unit-section-large;
    }

    &.full {
        max-width: 100%;
    }

    &.padding-bottom {
        padding-bottom: $spacing-unit-section;
    }

    &--resources {
        padding-left: 3rem;
        padding-right: 3rem;

        > :first-child {
            max-width: unset;
            padding-left: px-to-rem(125px);
            padding-right: px-to-rem(125px);
            padding-top: 2rem;

            @include bp-max($bp-people-desktop) {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                padding-top: 1rem;
            }
        }

        @include bp-max($bp-people-desktop) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    &--resources-section {
        max-width: px-to-rem($width-max-page);
        margin-left: auto;
        margin-right: auto;
        background-color: $color-brand-white;
    }

    &--resources-section-wide {
        background-color: $color-brand-white;

        &:first-of-type {
            padding: 0;
        }
    }

    &--video {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        padding-top: 1rem;

        @include bp-min($bp-desktop) {
            padding: px-to-rem(30px);
            padding-bottom: 0;
        }
    }

    &--larger {
        max-width: px-to-rem(960px);
    }
}


.page-details {
    $padding-side: 2rem;

    margin-top: $spacing-unit-section-large;

    @include bp-min($bp-mobile-landscape) {
        // padding-left: $padding-side;
        // padding-right: $padding-side;
    }
}

@mixin content-wrapper {
    $padding: 1rem;

    padding-left: 0;
    padding-right: 0;

    @include bp-min($bp-mobile-landscape) {
        // padding-left: $padding*2;
        // padding-right: $padding*2;
    }
}

.content-wrapper {
    @include content-wrapper;
}


.site-main {
    transition: transform $animation-mobile-timing $easeInOutQuint;

    @include bp-max($bp-mobile) {
        .is-active-mobile-menu & {
            transform: translateX($site-mobile-width);
        }
    }
}




//rewritting start

main {
    opacity: 1;
    transition: background-color 0.5s ease-in-out;
    background-color: rgba(255,255,255,0);

    &:before {
        height: 0;
        width: 0;
        z-index: -1;
    }

    .main-animation-container {
        &.is-transitioning {
            position: relative;
            background-color: rgba(255,255,255,0.9);

            &:before {
                content: "";
                z-index: 101;
                position: absolute;
                height: 100%;
                width: 100%;
                background-color: inherit;
            }

            .loading-animation {
                $animation-size: px-to-rem(120px);
                $animation-half: $animation-size / 2;
                $sidebar-width-half: $site-header-nav-width / 2;
                display: block;
                position: fixed;
                top: 50%;
                left: calc(50vw + #{$sidebar-width-half} - #{$animation-half});
                //transform: translate(-50%, -50%);
                width: $animation-size;
                animation: rotation 2s infinite linear;
                z-index: 103;

                @include bp-max($bp-tablet) {
                    $sidebar-width-half: $site-header-nav-width-tablet / 2;
                    left: calc(50vw + #{$sidebar-width-half} - #{$animation-half});
                }

                @include bp-max($bp-mobile) {
                    left: calc(50vw - #{$animation-half});
                }
            }
        }
    }

    .loading-animation {
        display: none;
    }
}


.main-wrapper {
    padding: 1rem;
    min-height: calc(100vh - #{px-to-rem($site-header-bar-height + $site-footer-height)});
    //body offset
    margin-top: 2.5rem;


    @include bp-min($bp-mobile-landscape) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @include bp-max($bp-mobile) {
        //body offset
        margin-top: 6.1rem;
        min-height: calc(100vh - #{$site-location-bar-height + $site-footer-height + $site-header-height});
    }

    @include bp-min($bp-desktop) {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    &--resources {
        padding: 0;

        .site-utility-bar {
            background-color: $color-brand-white;
            width: 100%;
            padding-bottom: px-to-rem(25px);
            padding-top: px-to-rem(20px);
            padding-left: px-to-rem(25px);
            padding-right: px-to-rem(25px);

            .language-bar {
                margin-bottom: 0;
            }

            @include bp-max($bp-desktop) {
                padding-left: 1rem;
            }
            @include bp-max($bp-mobile-landscape) {
                padding-bottom: px-to-rem(10px);
            }

            @include bp-min(400px) {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
            
                .breadcrumb-nav {
                  margin-top: 0;
                  flex-grow: 1;
                  flex-shrink: 1;
                  flex-basis: 0%;
                  min-width: 0;
                }
            }
        }
    }
}

.site-fixedbackground {
    background-size: cover;
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: -1;
    background-position: center;

    @include bp-min($bp-desktop) {
        background-attachment: fixed;
    }
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

@mixin module-wrapper {
    max-width: px-to-rem(865px);
    margin-left: auto;
    margin-right: auto;
}
