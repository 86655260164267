.site-nav,
.site-search,
.site-search-btn,
.nav-mobile-toggle,
.breadcrumb-nav,
.site-location-bar,
.globalization-menu,
.footer-links,
.cookie-popup{
	display: none;
}

html {
	font-size: 12pt;
}

body {
	padding-left: 0;
}

//header 
.header-logo {
	display: block;

	> img {
		display: block;
		max-width: px-to-rem(260px);
	}
}

.main-wrapper {
	padding: 0;
}

//Skip to content
a#skip-nav {
    display: none;
}

//Cookie controls
.cookie-controls__reopen {
    display: none;
}



