.people-gallery,
.people-hero-carousel,
.jump-nav,
.language-bar  {
	display: none;
}

.people-hero {
	overflow: hidden;
	padding: 3.5%;
	border: 1px solid $border-color-input;
}

.people-hero-image {
	float: left;
	margin: 0 3.5% $spacing-unit 0;
}

.people-hero-pic {
	display: block;
  	max-width: none;
	height: px-to-rem(360px);
    width: auto;
    aspect-ratio: auto 375/525;
}


.people-hero-content {
	flex-grow: 1;
	position: relative;
}

.people-hero-header {
    $section-spacing: px-to-rem(15px);

    margin-bottom: $section-spacing;
    text-align: center;

}

.people-hero-practices {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    text-align: center;
    color: $color-text-gray;

    .practice-item {
        @extend %body-2;

        margin: px-to-rem(6px) 0 0;
    }
}

.people-hero-body {
	$section-spacing: px-to-rem(15px);
	@extend %body-3;

	clear: both;
	text-align: center;

	&:before {
    		content: '';
    		display: block;
    		width: 100%;
    		padding-top: $spacing-unit;
    		border-top: 1px solid $border-color-divider;
    	}

	.email {
		margin-bottom: px-to-rem(16px);
	}

  	.page-tools {
  		display: none;
  	}
}

.people-hero-contact-list {
	padding-left: 0;
}


.people-hero-contact {
	margin: px-to-rem(10px) 0 0;
	display: table;
	width: 100%;


	.itemprop {
		display: table-cell;
		width: 50%;
		padding-left: px-to-rem(5px);
		text-align: left;
		vertical-align: middle;
	}

	.location-name,
	.mobile-label {
		text-align: right;
		padding-left: 0;
		padding-right: px-to-rem(5px);
	}
}


.credentials {
  .section-header {
    margin-bottom: 2rem;
  }
}


.credentials-title {
	@extend .h6-size;
	@extend %uc;

	margin-bottom: 1rem;
}

.credentials-list {
	margin-top: $spacing-unit;
}

.credentials-item {
	.rich-text {
		@extend %body-4;
	}

	& + &  {
		padding-top: $spacing-unit-small;
	}

	&-disclaimer {
		@extend %body-2;
		font-size: px-to-rem(13px);
		padding-top: px-to-rem(5px);
		line-height: px-to-rem(16px);
	}
}