/**
 *
 * Colors
 *
 */

$color-brand-white:					#fff;
$color-brand-black:					#322f31;

$color-brand-dark-black: 			#000;
$color-brand-charcoal-black: #131313;


//Greys
$color-brand-gray:					#ebebeb;
$color-gray-med:					#656365;
$color-gray-med-alt:				#C8C8C8;
$color-gray-mortar:					#535353;
$color-brand-dark-gray:       		#373737;
$color-brand-gray-light: 			#f5f5f5;
$color-gray-gainsboro:				#E2E2E2;
$color-gray-white-smoke:			#f2f2f2;
$color-gray-white-dust:             #ddd;
$color-gray-night-rider:			#303030;
$color-gray-darker-gray:			#444;
$color-gray-soft: #DFDFDF;

//green
$color-brand-green: 				#94a016;
$color-green-blue-lagoon:			#005e85;
$color-green-gray:                  #88ACB6;

$color-brand-purple: 				#604658;
$color-brand-eggplant: 				#604658;

//red
$color-brand-red: 					#C51F4B;

//yellow
$color-brand-yellow: 				#FDB515;
$color-orange:						#eea502;
$color-gold: #F4C04A;


//Blues
$color-brand-blue:     				#005e85;
$color-shade-blue:					#326699;
$color-blue-dark-cerulean:			#054F6D;
$color-green-dark-green:			#02202c;
$color-navy: #355E87;
$color-navy-dark: #283643;

//purple
$color-cta-purple: #5C4757;

//opaque
$opaqueblack: 						rgba(0, 0, 0, 0.7);
$opaquePurple: 						rgba(96, 70, 88,0.7);
$box-shadow: 						0 0 4px 2px rgba(0, 0, 0, 0.5);

//text colors
$color-text-link:           		$color-brand-blue;
$color-text-dark:           		#3f3f3f;

$color-text-base:           		#525252;
$color-text-gray:          			$color-gray-med;
$color-text-headings: 				#424242;
$color-text-gray-alt: 				#b0b0b0;
$color-text-light:					#959595;
$color-text-disabled:				#b0b0b0;
$color-text-icons: 					#989798;

//backgrounds
$bg-site:							#f5f6f6;
$bg-site-nav:						$color-brand-gray;
$bg-nav-secondary:					$color-brand-charcoal-black;
$bg-nav-bar:						#424242;
$bg-mobile-trigger: 				#e1e1e1;
$bg-color-lightgray:				$color-brand-gray;
$bg-articles: 						$color-brand-gray-light;
$bg-footer-home: 					#2E4255;

//borders
$border-color-base:           		#dadbdc;
$border-color-divider:				#d0d0d0;
$border-color-articles: 			#e9e9e9;
$border-color-input: 				#c7c7c7;


//buttons
$color-button-primary:				$color-brand-purple;
$color-button-primary-hover:		darken($color-brand-purple, 10);
$color-button-primary-disabled:		lighten($color-brand-purple, 30);



//Accessibility colors:
$color-accessibility-green-text: #0E677C