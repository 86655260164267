
//hiding elements, in particular articles grid list is not shown since it uses isotope layout which makes it really hard to make print friendly
.articles-grid-list,
.rte-expand-button,
.btn-read-more,
.btn--our-work-deep,
.services-sectors-cta,
.article-share-tools,
.careers-section-nav,
.single-cta,
.person-gallery,
//links to other sections is not relevant on print
.services-sectors-cta  {
	display: none;
}


.jump-sections  {
	margin-top: $spacing-unit-section;
}


.rte-expand-full,
 {
	display: block !important;
}

.tab-menu {
	.menu-items {
		display: none;
	}
}

.tab-menu-btn,
.tabs-module-mobile-next  {
	display: none;
}

.related-services {
	margin-top: $spacing-unit-section-large;

	.category-name {
		text-transform: uppercase;
		font-family: $font-family-sans-serif-bold;
		font-size: px-to-rem(14px);
	}

	.services {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.service {
		@extend %body-4;

		display: block;
		margin: 0;
		padding-top: px-to-rem($font-size-base-px);
		font-size: $font-size-small;
    	position: relative;
	}
}

.sector-reach-item {
	margin-top: $spacing-unit-block;
}

.related-insights {
	margin-top: $spacing-unit-section-large;
}

.credentials {
	margin-top: $spacing-unit-section-large;
}

.contacts-links {
	margin-top: $spacing-unit-section-large;	
}

.contacts-links-list {
	margin-top: $spacing-unit-block;
	padding-left: 0;
}

.contacts-links-item {
	text-align: center;
}

.contacts-links-link,
.contacts-links-nonlink {
	@extend %body-3;

	background-color: $color-brand-blue;
	display: block;
	background-color: $color-brand-gray;
	padding: 1rem;
}

.insights-list {
	margin: $spacing-unit 0 0;
	padding-left: 0;
}

.insights-list-item {
	& + & {
		margin-top: $spacing-unit;
		padding-top: $spacing-unit;
		border-top: 1px dotted $border-color-divider;
	}
}

.insight-item-title {
	font-size: 1.35rem;
	line-height: 1.4;
}

.insight-item-tag {
	@extend %body-5;

	color: $color-brand-red;
	margin-bottom: .5rem;
}

.insight-item-link {
  color: $color-text-dark;
  font-size: px-to-rem(22px);
  list-height: 1.4;
  display: inline-block;
}


.insight-item-meta {
	@extend .small-text-size;

	color: $color-text-gray;
	font-style: italic;
	margin-top: px-to-rem(5px);
}

.insight-item-meta-item {
 	&:after {
 		content: '|';
 		display: inline-block;
 		padding:0 .3rem 0 .5rem;
 	}
 	&:last-child {
 		&:after {
 			content: '';
 			padding:0;
 		}
 	}
}

//contact cards
$contact-module-padding: .75rem;
$contacts-module-padding-left: 1.25rem;
.contact-card {
	position: relative;
	transition: background-color .3s;
}

.contact-link {
	background-color: $color-brand-gray-light;
	color: $color-text-gray;
	transition: color .3s;
	display: inline-block;
	width: 100%;


	@media (min-width: px-to-rem(550px)) {
		height: px-to-rem(161px);
	}
}

.contact-name {
	transition: color .3s;
	font-size: px-to-rem(18px);

}

.contact-name, .contact-level {
	line-height: px-to-rem(24px);
}

.contact-image {
	float: left;
	width: 4.5rem;

	@media (min-width: px-to-rem(550px)) {
		padding-left: 0;
		padding-top: 0;
    	height: 100%;
		width: auto;
	}
}

.contact-link-detail-section {
	@media (min-width: px-to-rem(550px)) {
		padding-top: 0.5rem;
	 }
}

.contact-details {
	@extend %body-4;
	padding: $contact-module-padding;
	padding-left: $contacts-module-padding-left;
	overflow: hidden;
	height: 100%;
}

.contact-location {
	@extend %body-2;
	bottom: $contact-module-padding;
}


.contact-region, .contact-location {
	vertical-align: baseline;

	display: inline-block;
}

.contact-region__spacer {
	&::after {
		content: ', ';
		display: inline-block;
		vertical-align: text-top;
		white-space: pre;
		margin-left:-4px;
	}
}

.contact-region {
	display: inline-block;
	font-size: 0.7rem;
	text-transform: uppercase;
	vertical-align: baseline;
}

.contact-email {
	text-transform: uppercase;
}

.contact-email, .contact-tel {
	display: block;
	@extend %body-5;
    letter-spacing: .05rem;
	line-height: px-to-rem(18px);
}

.contact-level {
	color: $color-brand-black;
	font-size: px-to-rem(16px);
}

.contacts-module-meet-team {
	margin-top: $spacing-unit-block;
	text-align: center;
}

.contacts-module-cta-container {
	margin-top: 2.6rem;
	text-align: center;

	.cta-links-link {
		display: inline-block;
	}
}


.search__padding-wrapper {
    margin-bottom: 3rem;
}

.search-results-list {
	$spacing: .5rem;

	list-style-type: none;
	padding: 0;
	margin: 0;

	&:not(:first-of-type).hide-list {
		display: none;
	}

	.result-tag {
		letter-spacing: 0.05rem;
		color: $color-brand-red;
		text-transform: uppercase;
		font-family: $font-family-sans-serif;
		font-size: px-to-rem(12px);
	}

	.result-title {
		font-family: $font-family-primary;
		color: $color-text-dark;
		font-size: px-to-rem(22px);
		margin-top: $spacing;
		&:lang(ja){
			font-family: $font-family-primary-ja;
		}
	}

	.result-meta {
		color: $color-gray-med;
		font-family: $font-family-primary;
		font-style: italic;
		margin-top: $spacing;
		&:lang(ja){
			font-family: $font-family-primary-ja;
		}
	}

	.result-meta-item {
		font-size: px-to-rem(14px);

		&:after {
			content: '|';
			font-style: normal;
			padding-left: .5rem;
			padding-right: .25rem;
		}

		&:last-of-type {
			&:after {
				content: none;
			}
		}
	}

	.result-region-tag {
		@extend .result-tag;

		+ .result-tag {
			font-family: $font-family-primary;
			font-size: 0.9rem;
			font-style: italic;
			text-transform: none;
			&:lang(ja){
				font-family: $font-family-primary-ja;
			}

			&::before {
			    content: '  |  ';
				white-space: pre;
			}
		}
	}

	.result-preview-text {
		margin-bottom: 0;
		margin-top: 0.5rem;
	}
}

.search-result {
	$padding: 0.8rem;

	border-bottom: 1px solid $color-gray-gainsboro;
	padding: $padding 0 $padding * 2;

	&:first-of-type {
		padding-top: 0;
	}
}

//articles
.article-page-header {
	$spacing: 1.5rem;

	text-align: center;
	padding-bottom: $spacing;
	margin-bottom: $spacing;
	border-bottom: 1px solid $color-gray-gainsboro;

	img {
		margin-top: 1rem;
		width: 100%;
	}

	.language-bar {
		margin-bottom: 0;
	}

	.tag {
		@extend %body-5;

		color: $color-brand-red;
	}

	.title {
		margin-top: $spacing;
		font-weight: normal;
	}

	.article-date {
		@extend %body-2;

		margin-top: $spacing;
	}
}

.article-summary {
	padding:0 2.5rem 2.5rem;

	.summary-label {
		@extend .h5-size;

		color: $color-brand-dark-black;
		font-family: $font-family-sans-serif-bold;
	}

	.rich-text {
		@extend .h4-size;

		margin: 1.5rem 0 0;
	}
}