$font-size-base-px:           18px;

$width-max-page: 865px;
$width-max-home: 1200px;

///////////////////////////////////////////////////////////////////////////////
//
//	Spacing
//
///////////////////////////////////////////////////////////////////////////////

$site-padding:						px-to-rem(50px);
$site-padding-desktop:				px-to-rem(40px);
$site-padding-tablet:				px-to-rem(30px);
$site-padding-mobile:               3.25%;


$spacing-unit:						px-to-rem(25px);
$spacing-unit-block:				$spacing-unit * 1.5; //36
$spacing-unit-section:				px-to-rem(50px);   //50
$spacing-unit-section-large:        px-to-rem(70px);   //50
$spacing-unit-small:                px-to-rem(10px);   //10px
$spacing-unit-smallest:             px-to-rem(5px);


///////////////////////////////////////////////////////////////////////////////
//
//	Structure
//
///////////////////////////////////////////////////////////////////////////////

$site-header-bar-height: 			40px;
$site-header-nav-width:				15%;
$site-header-nav-width-desktop:     22.2%;
$site-header-nav-width-tablet:		210px;
$site-search-width: 				50px;

$site-header-height:                55px;
$site-location-bar-height:          38px;
$site-header-and-location-height: $site-header-height + $site-location-bar-height;

$site-mobile-width:                 250px;

$site-footer-height:                152px;


$homepage-last-panel-height:        420px;
$homepage-last-panel-height-mobile: 450px;

///////////////////////////////////////////////////////////////////////////////
//
//	ZINDEX
//
///////////////////////////////////////////////////////////////////////////////


$zindex-modal-bg:           		1000;
$zindex-modal-body:         		1005;
$zindex-header:           			550;
$zindex-header-main:           		500;
$zindex-header-bar:           		700;
$zindex-location-bar:           	750;
$zindex-cookie-popup:               1100;
$z-index-back-to-top-button:        1050;
$z-index-homepage-experience:       10;
$z-index-homepage-footer:           $z-index-homepage-experience + 1;

///////////////////////////////////////////////////////////////////////////////
//
//	Animations
//
///////////////////////////////////////////////////////////////////////////////

$base-animation-timing:				.25s;
$base-animation-ease:				ease-in;
$base-animation:    				color $base-animation-timing $base-animation-ease,
				                    border-color $base-animation-timing $base-animation-ease,
									opacity $base-animation-timing $base-animation-ease,
                                    font-weight $base-animation-timing $base-animation-ease,
				                    background-color $base-animation-timing $base-animation-ease;

$base-animation-minus-color:        border-color $base-animation-timing $base-animation-ease,
                                    opacity $base-animation-timing $base-animation-ease,
                                    font-weight $base-animation-timing $base-animation-ease,
                                    background-color $base-animation-timing $base-animation-ease;

$animation-locations-timing:		.4s;
$animation-locations-ease:			ease-in;

$easeInOutQuint: 					cubic-bezier(0.86, 0, 0.07, 1);

$easing:                            $easeInOutQuint;


$animation-mobile-timing: 			.6s;


///////////////////////////////////////////////////////////////////////////////
///
///  Grid
///
///////////////////////////////////////////////////////////////////////////////

$susy: (
    flow: ltr,
    math: fluid,
    output: float,
    gutter-position: after,
    container: auto,
    container-position: center,
    columns: 12,
    gutters: .3333,
    column-width: false,
    global-box-sizing: border-box,
    last-flow: to,
    debug: (
        image: hide,
        color: rgba(#66f, .25),
        output: background,
        toggle: top right,
    ),
    use-custom: (
        background-image: false,
        background-options: false,
        box-sizing: false,
        clearfix: false,
        rem: false,
    )
);


