//  Fonts
///////////////////////////////////////////////////////////////////////////////
//
//  Font Size REMS
//  Base: 18px
//  /**
// Fonts:
//  * Via Fonts.com
//  - AVENIR MEDIUM - 400
//  - AVENIR Black - 400
//  * Via typekit
//  - KEPLER REGULAR
//  - KEPLER DISPLAY
//
///////////////////////////////////////////////////////////////////////////////

//base pixels
$font-size-base:              112.5%;  //18 pixels
$font-size-small:             px-to-rem($font-size-base-px/1.25);

//families
$font-family-primary:         "kepler-std", "Times New Roman", "SimSun";
$font-family-titles:          "kepler-std-display", "Times New Roman", "SimSun";
$font-family-fallback:        "Times New Roman", "SimSun";

$font-family-primary-ja:         "kepler-std", "Times New Roman", Hiragino Mincho ProN, "MS Mincho";
$font-family-titles-ja:          "kepler-std-display", "Times New Roman", Hiragino Mincho ProN, "MS Mincho";
$font-family-titles-ja-plain:    "kepler-std-display", "Times New Roman", Hiragino Mincho ProN, "MS Mincho";
$font-family-fallback-ja:        "Times New Roman", Hiragino Mincho ProN, "MS Mincho";


$font-family-sans-serif:      "Avenir LT W01_65 Medium1475532", Hiragino Mincho ProN, "MS Mincho";
$font-family-sans-serif-bold: "Avenir LT W01_95 Black1475556", Hiragino Mincho ProN, "MS Mincho";

//weights
$font-weight-light:           300;
$font-weight-regular:         400;
$font-weight-bold:            700;
$font-weight-bold-extra:      900;

//icons
$font-family-icons: "Black Tie";
$font-family-font-awesome: 'Font Awesome 5 Brands', 'Font Awesome 5 Free';
$font-family-font-awesome-brands: 'Font Awesome 5 Brands', 'Font Awesome 5 Free';


//Can't use shorthand font declaration since IE9 breaks once you declare font size in REM
html {
    font-size: $font-size-base;
    font-family: $font-family-primary;
    font-weight: $font-weight-regular;
    line-height: 1.35;

  	@include bp-max($bp-tablet) {
  		font-size: 100%;
  	}

    @include bp-max($bp-mobile) {
  		font-size: 95%;
  	}
    //Use different font for ja
  &:lang(ja){
    font-family: $font-family-primary-ja;
  }
}


h1,h2,h3,h4,h5,h6,
.h1-size,
.h2-size,
.h3-size,
.h4-size,
.h5-size,
.h6-size  {
  font-family: $font-family-primary;
  font-weight: $font-weight-regular;
	line-height: 1.2;
	margin: 0 0 $spacing-unit;
   -webkit-font-smoothing: antialiased;
   color: $color-text-headings;
   &:lang(ja){
    font-family: $font-family-primary-ja;
  }
}

h1,
.h1-size {
  font-size: 2rem;
  line-height: 1.1;
  margin-bottom: 1rem;

	@media (min-width: $bp-mobile-portrait) {
       font-size: px-to-rem(48px);
	}
}

h2,
.h2-size {
  font-size: px-to-rem(36px);
  margin-bottom: $spacing-unit/2;
}

h3,
.h3-size  {
  font-size: px-to-rem(30px);
  font-family: $font-family-primary;
  &:lang(ja){
    font-family: $font-family-primary-ja;
  }
}

h4,
.h4-size  {
  font-size: px-to-rem(24px);
  line-height: 1.4;
  font-family: $font-family-primary;
  &:lang(ja){
    font-family: $font-family-primary-ja;
  }
}

h5,
.h5-size  {
  font-size:  px-to-rem(18px);
  font-family: $font-family-sans-serif-bold;
}

h6,
.h6-size  {
  font-size:  px-to-rem(15px);
  font-family: $font-family-sans-serif-bold
}

//helper text size
%giga-size {
  font-size: 4rem;

  @include bp-max($bp-mobile) {
    font-size: 3.75rem;
  }

  @include bp-max($bp-mobile-portrait) {
    font-size: 2.2rem;
  }
}

.giga-size {
  @extend %giga-size;

  font-weight: $font-weight-light;

  @include bp-max($bp-mobile) {
    font-size: 2.2rem;
  }
}

%large-size {
  font-family: $font-family-titles;
  font-size: 3rem;
  line-height: 1.2;
  letter-spacing: 0.05rem;
  &:lang(ja){
    font-family: $font-family-titles-ja;
  }

  @include bp-max($bp-mobile) {
    font-size: 2rem;
  }
}

%hero-size {
  font-family: $font-family-titles;
  font-weight: $font-weight-regular;
  font-size: px-to-rem(60px);
  line-height: 1.1;
  &:lang(ja){
    font-family: $font-family-titles-ja-plain;
  }
}

%body-1 {
  font-family: $font-family-primary;
  font-size: px-to-rem(18px);
  &:lang(ja){
    font-family: $font-family-primary-ja;
  }

}

%body-2 {
  font-family: $font-family-primary;
  font-size: px-to-rem(16px);
  font-style: italic;
  line-height: 1.2;
  &:lang(ja){
    font-family: $font-family-primary-ja;
  }
}

%body-3 {
  font-size: px-to-rem(15px);
  line-height: 1.2;
  letter-spacing: .1rem;
  text-transform: uppercase;
  font-family: $font-family-sans-serif;
}

%body-4 {
  font-size: px-to-rem(15px);
  font-weight: $font-weight-regular;
  line-height: 1.2;
  font-family: $font-family-sans-serif;
}

%body-5 {
  font-size: px-to-rem(12px);
  font-weight: $font-weight-regular;
  line-height: 1.2;
  letter-spacing: .1rem;
  text-transform: uppercase;
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  font-style: normal;
}

%body-6 {
  font-family: $font-family-sans-serif;
  font-weight: $font-weight-regular;
  font-size: px-to-rem(12px);
  line-height: 1.2;
}


//Old files
.small-text-size {
  font-size: 0.8rem; //14pixels
  line-height: 1.2;
}

%smallest-text-size{
  font-size: 0.6rem;
  line-height: 1.2;
  letter-spacing: .1rem;
}


//common headings
%header-with-rule {
  font-family: $font-family-titles;
  font-size: px-to-rem(36px);
  margin-bottom: 0;
  &:lang(ja){
    font-family: $font-family-titles-ja;
  }

}